import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { List } from "immutable"
import classNames from "classnames"
import Imgix from "highline/components/imgix"
import styles from "highline/styles/components/pdp/pdp_drawer_images.module.css"

const PDP_IMAGE_WIDTH = 285 // 285px is max width from product_preview.css
const PDP_SINGLE_IMAGE_WIDTH = 375 // 375px is max width from thumbnail_gallery.css
const PDP_MODAL_IMAGE_WIDTH = 350

const PdpDrawerImages = ({ images, isModal }) => {
  let width
  let classes
  if (images.size > 1) {
    width = PDP_IMAGE_WIDTH
    classes = styles.slideWrapper
  } else {
    width = PDP_SINGLE_IMAGE_WIDTH
  }
  if (isModal == true) {
    width = PDP_MODAL_IMAGE_WIDTH
    classes = styles.slideWrapperModalImage
  }

  return (
    <div className={classNames("component", "pdp-drawer-images-component", styles.component)}>
      {images.map((imageData) => (
        <div className={classes} key={imageData.get("url")}>
          <Imgix
            htmlAttributes={{
              alt: imageData.get("caption"),
              "aria-label": imageData.get("caption"),
            }}
            src={imageData.get("url")}
            width={width}
          />
        </div>
      ))}
    </div>
  )
}

PdpDrawerImages.propTypes = {
  images: ImmutablePropTypes.list,
  isModal: PropTypes.bool,
  showImages: PropTypes.bool,
}

PdpDrawerImages.defaultProps = {
  images: List(),
  isModal: false,
  showImages: true,
}

export default PdpDrawerImages
