import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import classNames from "classnames"
import AlertTag from "highline/components/alert_tag"
import Delay from "highline/components/delay"
import Imgix from "highline/components/imgix"
import OnlyXLeft from "highline/components/only_x_left"
import Spinner from "highline/components/spinner"
import { CloseIcon, MinusIcon, PlusIcon } from "highline/components/icons"
import Modal from "highline/components/modal"
import Link from "highline/components/secure_link"
import ProductPreviewModalContainer from "highline/containers/product_preview_modal_container"
import Watermark from "highline/components/watermark"
import FielderAccentBlk from "highline/svg/icons/fielder-accent-blk.svg"

import styles from "highline/styles/components/cart/line_item.module.css"

class LineItem extends React.PureComponent {
  static propTypes = {
    bundles: ImmutablePropTypes.list,
    children: PropTypes.node,
    className: PropTypes.string,
    description: PropTypes.string.isRequired,
    description2: PropTypes.string,
    disableQuantityAdjustment: PropTypes.bool,
    discountedTotal: PropTypes.string,
    finalSale: PropTypes.bool,
    inStock: PropTypes.bool,
    shouldShowModal: PropTypes.bool,
    isAddToCartToast: PropTypes.bool,
    isFielderProduct: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isPromoCodeApplied: PropTypes.bool,
    itemId: PropTypes.string,
    isRemoveHidden: PropTypes.bool,
    layout: PropTypes.string,
    limitedQuantity: PropTypes.number,
    lineItemId: PropTypes.number,
    link: PropTypes.string.isRequired,
    message: PropTypes.string,
    name: PropTypes.string.isRequired,
    newQuantity: PropTypes.number,
    onDecrement: PropTypes.func,
    onDetailsClicked: PropTypes.func,
    onIncrement: PropTypes.func,
    onDelete: PropTypes.func,
    onRemove: PropTypes.func,
    onUnauthenticatedSaveItemClick: PropTypes.func,
    onWishlist: PropTypes.func,
    price: PropTypes.string,
    quantity: PropTypes.number,
    selectedOptions: PropTypes.object,
    showPrompt: PropTypes.bool,
    sku: PropTypes.string,
    slug: PropTypes.string,
    thumbnailSrc: PropTypes.string,
    unitPrice: PropTypes.string,
  }

  static defaultProps = {
    disableQuantityAdjustment: false,
    shouldShowModal: false,
    isAddToCartToast: false,
    message: "",
    isRemoveHidden: true,
    onDecrement: () => {},
    onDetailsClicked: () => {},
    onIncrement: () => {},
    onRemove: () => {},
  }

  state = {
    loading: false,
    showProductModal: false,
  }

  loadingComplete = () => {
    this.setState({ loading: false })
  }

  increment = () => {
    this.setState({ loading: true })

    const lineItems = fromJS([
      {
        isBundle: false,
        location: "cart",
        name: this.props.name,
        price: this.props.price,
        discountedTotal: this.props.discountedTotal,
        quantity: 1,
        sku: this.props.sku,
      },
    ])

    this.props.onIncrement(lineItems, this.loadingComplete)
  }

  decrement = () => {
    this.setState({ loading: true })

    const lineItems = fromJS([
      {
        price: this.props.price,
        discountedTotal: this.props.discountedTotal,
        quantity: 1,
        newQuantity: this.props.quantity - 1,
        sku: this.props.sku,
        itemId: this.props.itemId,
      },
    ])

    this.props.onDecrement(lineItems, this.loadingComplete)
  }

  renderQuantitySubcomponent = () => {
    const { disableQuantityAdjustment, quantity } = this.props
    const { loading } = this.state

    return (
      <Fragment>
        <div className={styles.quantity}>
          {!disableQuantityAdjustment && (
            <button
              aria-label="Decrease the item quantity by one"
              className={classNames(styles.quantityButton, styles.decrement)}
              onClick={this.decrement}
              disabled={loading}
            >
              <MinusIcon />
            </button>
          )}

          <span className={styles.qtyWrapper}>
            {loading ? (
              <Delay delay={200}>
                <Spinner className={styles.spinner} />
              </Delay>
            ) : (
              <span className={styles.qty}>
                {disableQuantityAdjustment && "Qty "}
                {quantity}
              </span>
            )}
          </span>

          {!disableQuantityAdjustment && (
            <button
              aria-label="Increase the item quantity by one"
              className={classNames(styles.quantityButton, styles.increment)}
              onClick={this.increment}
              disabled={loading}
            >
              <PlusIcon />
            </button>
          )}
        </div>
      </Fragment>
    )
  }

  renderLineItemBundles = (bundles) => {
    if (!bundles || bundles.size < 1) {
      return null
    }
    const bundleNames = Array.from(new Set(bundles.map((b) => b.get("bundleName"))))
    return (
      <Fragment>
        {bundleNames.map((name, index) => (
          <div
            key={`bundle-${index}`}
            className={classNames([styles.description, "lineItemBundle"])}
          >
            {`Bundle: ${name}`}
          </div>
        ))}
      </Fragment>
    )
  }

  renderProductModal = () => {
    const { slug } = this.props

    return (
      this.state.showProductModal && (
        <Modal
          layout={"roundedFlexibleFullscreen"}
          onRequestClose={() => this.setState({ showProductModal: false })}
          maxWidthLimit
        >
          <ProductPreviewModalContainer isModal slug={slug} />
        </Modal>
      )
    )
  }

  renderLinkToPdpOrModal = () => {
    const { name, link, onDetailsClicked, selectedOptions, slug, shouldShowModal } = this.props

    return shouldShowModal ? (
      <button
        onClick={() => {
          onDetailsClicked(slug, selectedOptions)
          this.setState({ showProductModal: true })
        }}
        className={styles.name}
        aria-label={`Open Product Details Modal for ${name}`}
      >
        {name}
      </button>
    ) : (
      <Link href={link} className={styles.name} data-cy={"name-links-to-pdp-page"}>
        {name}
      </Link>
    )
  }

  renderAppropriatePdpThumbnail = (image) => {
    const { name, link, onDetailsClicked, selectedOptions, showPrompt, slug, shouldShowModal } =
      this.props

    return shouldShowModal ? (
      <button
        onClick={() => {
          onDetailsClicked(slug, selectedOptions)
          this.setState({ showProductModal: true })
        }}
        className={styles.modalThumbnail}
        aria-label={`Open Product Details Modal for ${name}`}
      >
        {image}
      </button>
    ) : (
      <Link href={link} tabIndex={showPrompt ? -1 : 0} data-cy={"image-links-to-pdp-page"}>
        {image}
      </Link>
    )
  }

  renderSaveForLaterAndRemoveButtons = (itemName, onWishlistCallback, onDeleteCallback) => (
    <div className={styles.saveForLaterAndRemoveContainer}>
      <button
        onClick={onWishlistCallback}
        className={styles.name}
        aria-label={`Save ${itemName} for later and add to wishlist`}
      >
        Save for Later
      </button>
      <button
        onClick={onDeleteCallback}
        className={styles.name}
        aria-label={`Remove ${itemName} from cart`}
      >
        Remove
      </button>
    </div>
  )

  render() {
    const {
      bundles,
      children,
      className,
      description,
      description2,
      discountedTotal,
      finalSale,
      name,
      onDelete,
      onRemove,
      onUnauthenticatedSaveItemClick,
      onWishlist,
      message,
      price,
      quantity,
      isAddToCartToast,
      isFielderProduct,
      isLoggedIn,
      isPromoCodeApplied,
      isRemoveHidden,
      layout,
      selectedOptions,
      shouldShowModal,
      showPrompt,
      sku,
      slug,
      thumbnailSrc,
      inStock,
      limitedQuantity,
      unitPrice,
    } = this.props

    const hasDiscount = discountedTotal !== price

    const showSaveAndRemoveButtons = onWishlist || onUnauthenticatedSaveItemClick

    const quanityStockComponent = (
      <div className={styles.quanityStockContainer}>
        {quantity && this.renderQuantitySubcomponent()}
      </div>
    )

    const priceComponent = price && (
      <div className={styles.priceContainer}>
        <div className={classNames(styles.price, hasDiscount && styles.strikethrough)}>
          {`${price}${isPromoCodeApplied && !hasDiscount ? " - Excluded from promotion" : ""}`}
        </div>
        {hasDiscount && <div className={styles.discountedTotal}>{discountedTotal}</div>}
      </div>
    )

    return (
      <div
        className={classNames(
          "component",
          "line-item-component",
          styles.component,
          hasDiscount && styles.hasBundle,
          className,
          styles[layout],
          isAddToCartToast && styles["add_to_cart_toast"]
        )}
      >
        <div className={styles.itemContainer}>
          {this.renderAppropriatePdpThumbnail(
            <span className={styles.thumbnailContainer}>
              <Imgix
                className={styles.thumbnail}
                htmlAttributes={{
                  alt: `${name} thumbnail`,
                  "aria-label": `${name} thumbnail`,
                }}
                src={thumbnailSrc}
                width={94} // Set by styles.thumbnail
              />
              {isFielderProduct && (
                <Watermark
                  svg={<FielderAccentBlk />}
                  placement="bottom-left"
                  type="line-item-icon"
                />
              )}
            </span>
          )}
          {!showPrompt && (
            <div className={styles.details}>
              {!isRemoveHidden && (
                <button
                  aria-label={`Remove ${name} from cart`}
                  className={classNames("close-button", styles.closeButton)}
                  onClick={() => onRemove(sku, quantity)}
                >
                  <CloseIcon />
                </button>
              )}
              <div className={styles.top}>
                {this.renderLinkToPdpOrModal()}
                <div className={styles.description}>{description}</div>
                {description2 && <div className={styles.description2}>{description2}</div>}
                {unitPrice && quantity > 1 && (
                  <div className={styles.description}>{`Price: ${unitPrice}`}</div>
                )}
                {this.renderLineItemBundles(bundles)}
                {inStock && limitedQuantity > 0 && limitedQuantity <= 5 && (
                  <OnlyXLeft
                    className={styles.onlyXLeft}
                    layout="small"
                    quantity={limitedQuantity}
                  />
                )}
                {layout === "cart_full_page" && quanityStockComponent}
                {layout === "cart_full_page" && priceComponent}
                {!inStock && (
                  <AlertTag className={styles.soldOut} layout="small" quantity={limitedQuantity} />
                )}
                {finalSale && (
                  <div className={styles.finalSale}>
                    Item is final sale. No returns or exchanges.
                  </div>
                )}
                {!inStock && (
                  <AlertTag className={styles.soldOut} layout="small">
                    Sold Out
                  </AlertTag>
                )}
                {layout === "cart_full_page" &&
                  showSaveAndRemoveButtons &&
                  this.renderSaveForLaterAndRemoveButtons(
                    name,
                    isLoggedIn
                      ? () => onWishlist(sku, quantity)
                      : () => onUnauthenticatedSaveItemClick(slug, selectedOptions),
                    isLoggedIn ? () => onDelete(sku, quantity) : () => onRemove(sku, quantity)
                  )}
                {shouldShowModal && this.renderProductModal()}
              </div>
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          {!(layout === "cart_full_page") && quanityStockComponent}
          {!(layout === "cart_full_page") && priceComponent}
        </div>
        {children}
        {message && <div className={styles.message}>{message}</div>}
      </div>
    )
  }
}

export default LineItem
