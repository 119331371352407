import { connect } from "react-redux"
import ProductPreview from "highline/components/pdp/product_preview"
import {
  productPreviewAddToCartAsync,
  productPreviewOptionToggled,
  productPreviewUpdateOptionsAsync,
} from "highline/redux/actions/product_preview_actions"
import { productPreviewViewDetailsClickedAsync } from "highline/redux/actions/right_drawer_actions"
import {
  outOfStockSubscriptionEmailChanged,
  outOfStockSubscriptionSubmitAsync,
} from "highline/redux/actions/out_of_stock_subscription_actions"
import { getField } from "highline/utils/contentful/contentful_helper"

const mapStateToProps = (state, ownProps) => {
  const productContentfulData = state.getIn(["productPreview", "contentful"])
  return {
    collapsedOptions: state.getIn(["productPreview", "collapsedOptions"]),
    coreColors: getField(productContentfulData, "coreColors"),
    finalSale: state.getIn(["productPreview", "finalSale"]),
    images: state.getIn(["productPreview", "images"]),
    isAddingToCart: state.getIn(["productPreview", "isAddingToCart"]),
    isGiftCard: state.getIn(["productPreview", "isGiftCard"]),
    isIconStatus: getField(productContentfulData, "iconStatus"),
    isLoading: state.getIn(["productPreview", "isLoading"]),
    isPreorder: state.getIn(["productDetail", "isPreorder"]),
    isReady: state.getIn(["productPreview", "isReady"]),
    metaTitle: state.getIn(["productPreview", "metaTitle"]),
    options: state.getIn(["productPreview", "options"]),
    outOfStockFormErrors: state.getIn(["outOfStockSubscription", "formErrors"]),
    outOfStockSubmitSuccess: state.getIn(["outOfStockSubscription", "wasSucess"]),
    outOfStockSubscriptionEmailInputValue: state.getIn(["outOfStockSubscription", "email"]),
    outOfStockSubscriptionErrors: state.getIn(["outOfStockSubscription", "errors"]),
    outOfStockSubscriptionIsLoading: state.getIn(["outOfStockSubscription", "isLoading"]),
    price: state.getIn(["productPreview", "price"]),
    productName: state.getIn(["productPreview", "name"]),
    remainingOptions: state.getIn(["productPreview", "remainingOptions"]),
    requestedOptions: state.getIn(["productPreview", "requestedOptions"]),
    selectedOptions: state.getIn(["productPreview", "selectedOptions"]),
    shortDescription: state.getIn(["productPreview", "shortDescription"]),
    showErrors: state.getIn(["productPreview", "showErrors"]),
    sku: state.getIn(["productPreview", "sku"]),
    slug: ownProps.slug || state.getIn(["productPreview", "slug"]),
    url: state.getIn(["currentPage", "url"]),
    userEmail: state.getIn(["auth", "email"]),
    variant: state.getIn(["productPreview", "variant"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleNotifyMeSubmit: (email, options, selectedOptions, productSlug, productName) => {
      dispatch(
        outOfStockSubscriptionSubmitAsync(email, options, selectedOptions, productSlug, productName)
      )
    },
    onAddToCart: () => {
      dispatch(productPreviewAddToCartAsync())
    },
    onLoad: () => {},
    onOptionChange: (optionName, optionValue) => {
      dispatch(productPreviewUpdateOptionsAsync(optionName, optionValue, false))
    },
    onOptionToggle: (optionName) => {
      dispatch(productPreviewOptionToggled(optionName))
    },
    onOutOfStockEmailChange: (email) => {
      dispatch(outOfStockSubscriptionEmailChanged(email))
    },
    onViewDetailsClicked: (route) => {
      dispatch(productPreviewViewDetailsClickedAsync(route))
    },
  }
}

const ProductPreviewModalContainer = connect(mapStateToProps, mapDispatchToProps)(ProductPreview)

export default ProductPreviewModalContainer
